import { PAGE_URL } from '../../config/appConfig';
/**
 * Company details data.
 * @function - to retrieve an array of Company details
 * @property {number} id - The unique ID of the company detail.
 * @property {string} href - The hyperlink for the company detail.
 * @property {string} Detail - The specific company detail (e.g., ABOUT_US, CAREERS, etc.).
 */

export const getCompanyDetail = (t) => {
  return [
    {
      href: PAGE_URL.ABOUT_US,
      detail: t('app.ABOUT_US'),
    },
    {
      href: PAGE_URL.CAREER_PAGE,
      detail: t('app.CAREERS'),
    },
    {
      href: PAGE_URL.LIFE_AT_XB,
      detail: t('app.FOOTER_LIFE_AT_XB'),
    },
    {
      href: PAGE_URL.NEWSPAGE_ALL,
      href: PAGE_URL.NEWSPAGE_ALL,
      detail: t('app.FOOTER_MEDIA/PRESS'),
    },
    {
      href: PAGE_URL.CONTACT_US,
      href: PAGE_URL.CONTACT_US,
      detail: t('app.CONTACT_US'),
    },
    {
      href: 'https://d2od166rb7u58d.cloudfront.net/ESG%20NEWS-2024.pdf',
      target: '_blank',
      detail: t('app.ESG_COMMITMENT'),
    },
  ];
};
/**
 * Service details data.
 * @function - to retrieve an array of Service details data
 * @property {number} id - The unique ID of the service detail.
 * @property {string} href - The hyperlink for the service detail.
 * @property {string} Detail - The specific service detail (e.g., CONSUMER, BUSINESS, etc.).
 */

export const getServiceDetail = (t) => {
  return [
    {
      href: PAGE_URL.SERVICES_B2C,
      href: PAGE_URL.SERVICES_B2C,
      detail: t('app.B2C'),
    },
    {
      href: PAGE_URL.SERVICES_B2B,
      href: PAGE_URL.SERVICES_B2B,
      detail: t('app.B2B'),
    },
    {
      href: PAGE_URL.SERVICES_CBL,
      href: PAGE_URL.SERVICES_CBL,
      detail: t('app.CROSS_BORDER'),
    },
    {
      href: PAGE_URL.SERVICES_TPL,
      href: PAGE_URL.SERVICES_TPL,
      detail: t('app.3PL'),
    },
  ];
};

/**
 * Legal details data.
 * @function - to retrieve an array of Legal details data.
 * @property {number} id - The unique ID of the legal detail.
 * @property {string} href - The hyperlink for the legal detail.
 * @property {string} Detail - The specific legal detail (e.g., FOOTER_PRIVACY, FOOTER_TERM, etc.).
 */

export const getLegalDetail = (t) => {
  return [
    {
      href: PAGE_URL.PRIVACY_POLICY,
      href: PAGE_URL.PRIVACY_POLICY,
      detail: t('app.FOOTER_PRIVACY'),
    },
    {
      href: PAGE_URL.VENDOR_POLICY,
      href: PAGE_URL.VENDOR_POLICY,
      detail: t('app.FOOTER_VENDOR'),
    },
    {
      href: PAGE_URL.TERMS_OF_USE,
      detail: t('app.FOOTER_TERMS_OF_USE'),
    },
  ];
};
/**
 * Social media links data.
 * @function - to retrieve an array of Social media links data.
 * @property {number} id - The unique ID of the social media link.
 * @property {string} href - The hyperlink for the social media link.
 * @property {string} alt - The alternative text for the social media link icon.
 * @property {string} src - The image source URL of the social media link icon.
 * @property {string} className - The CSS class name for the social media link icon.
 */

export const SOCIAL_MEDIA_LINKS = [
  {
    href: 'https://www.facebook.com/XpressBees.Official/',
    href: 'https://www.facebook.com/XpressBees.Official/',
    alt: 'Facebook',
    src: '/images/socialIcon/facebook.svg',
    target: '_blank',
  },
  {
    href: 'https://twitter.com/XpressBees_IN?t=0HW4Gp6A4Q4NPQpZjsNjew&s=03',
    href: 'https://twitter.com/XpressBees_IN?t=0HW4Gp6A4Q4NPQpZjsNjew&s=03',
    alt: 'Twitter',
    src: '/images/socialIcon/twitter.svg',
    target: '_blank',
  },
  {
    href: 'https://in.linkedin.com/company/xpressbees',
    href: 'https://in.linkedin.com/company/xpressbees',
    alt: 'Linkedin',
    src: '/images/socialIcon/linkedin.svg',
    target: '_blank',
  },
  {
    href: 'https://www.instagram.com/xpressbees_official/',
    alt: 'Instagram',
    href: 'https://www.instagram.com/xpressbees_official/',
    alt: 'Instagram',
    src: '/images/socialIcon/insta.svg',
    target: '_blank',
  },
];

import { PAGE_URL } from './appConfig';

export const getPage = (url) => {
  const pageURL = [
    PAGE_URL.HOMEPAGE,
    PAGE_URL.SERVICES_B2C,
    PAGE_URL.SERVICES_B2B,
    PAGE_URL.SERVICES_CBL,
    PAGE_URL.SERVICES_TPL,
    PAGE_URL.ABOUT_US,
    PAGE_URL.CAREER_PAGE,
    PAGE_URL.CONTACT_US,
    PAGE_URL.MEDIA_BLOGS,
    PAGE_URL.MEDIA_BLOGS_ALL,
    PAGE_URL.MEDIA_BLOGS_SINGLE,
    PAGE_URL.NEWSPAGE,
    PAGE_URL.NEWSPAGE_ALL,
    PAGE_URL.NEWSPAGE_SINGLE,
    PAGE_URL.SHIPMENT_TRACKING,
  ];
  return pageURL.indexOf(url) !== -1 ? url : '';
};

export const META_INFO = {
  [PAGE_URL.HOMEPAGE]: {
    title: 'XpressBees: Cost-Effective , Reliable and Tech-enabled Couriers',
    description:
      'Secure your deliveries with XpressBees: cost-effective, customer-centric, and reliable logistics for local, national, and international courier needs.',
    canonical: 'https://www.xpressbees.com',
  },
  [PAGE_URL.SERVICES_B2C]: {
    title: 'XpressBees: Nationwide, Fast, Reliable B2C Couriers',
    description:
      'Explore XpressBees and our B2C services for all courier needs, whether it be same-day delivery,next-day delivery,bulk shipment delivery,secure delivery or any other.',
    canonical: 'https://www.xpressbees.com/Services/B2C-Xpress',
  },
  [PAGE_URL.SERVICES_B2B]: {
    title: 'XpressBees- Streamlined B2B Logistics Services ',
    description:
      "Discover Xpressbees' B2B excellence: Streamlined operations, reliable logistics, and timely deliveries for business success. Partner with us today!",
    canonical: 'https://www.xpressbees.com/Services/B2B-Xpress',
  },
  [PAGE_URL.SERVICES_CBL]: {
    title:
      'Xpressbees - Global Courier Services | Fast & Secure International Shipping',
    description: 'Xpressbees: Fast & Secure Global Courier',
    canonical: 'https://www.xpressbees.com/Services/CrossBorderLogistics',
  },
  [PAGE_URL.SERVICES_TPL]: {
    title: 'XpressBees- Flexible and Efficient Warehouse Management Services',
    description:
      "Discover XpressBees' reliable warehouse solutions: Streamlined operations, real-time tracking, and customized services for optimal inventory management.",
    canonical: 'https://www.xpressbees.com/Services/ThirdPartyLogistics',
  },
  [PAGE_URL.SHIPMENT_TRACKING]: {
    title: 'Track Your Shipment | XpressBees Tracking',
    description:
      'Track your XpressBees shipment effortlessly. Real-time updates for local, national, and international courier. Experience Seamless tracking for efficient logistics.',
    canonical: 'https://www.xpressbees.com/shipment/tracking',
  },
  [PAGE_URL.MEDIA_BLOGS]: {
    title: 'XpressBees Blogs: Insights on Logistics & Supply Chain Solutions',
    description:
      "Explore XpressBees' blogs on logistics trends, supply chain, and industry insights. Stay updated with our expert articles for efficient business operations.",
    canonical: 'https://www.xpressbees.com/Blogs',
  },
  [PAGE_URL.MEDIA_BLOGS_SINGLE]: {
    title: 'XpressBees Blogs: Insights on Logistics & Supply Chain Solutions',
    description:
      "Explore XpressBees' blogs on logistics trends, supply chain, and industry insights. Stay updated with our expert articles for efficient business operations.",
    canonical: 'https://www.xpressbees.com/Blogs',
  },
  [PAGE_URL.MEDIA_BLOGS_ALL]: {
    title: 'XpressBees Blogs: Insights on Logistics & Supply Chain Solutions',
    description:
      "Explore XpressBees' blogs on logistics trends, supply chain, and industry insights. Stay updated with our expert articles for efficient business operations.",
    canonical: 'https://www.xpressbees.com/Blogs/All',
  },
  [PAGE_URL.NEWSPAGE_ALL]: {
    title: 'XpressBees News: Stay Informed on Logistics Innovations',
    description:
      'Keep up with the logistics innovations and updates at XpressBees. Explore our news section for valuable insights and trends in supply chain solutions.',
    canonical: 'https://www.xpressbees.com/News/All',
  },
  [PAGE_URL.NEWSPAGE]: {
    title: 'XpressBees News: Stay Informed on Logistics Innovations',
    description:
      'Keep up with the logistics innovations and updates at XpressBees. Explore our news section for valuable insights and trends in supply chain solutions.',
    canonical: 'https://www.xpressbees.com/News',
  },
  [PAGE_URL.NEWSPAGE_SINGLE]: {
    title: 'XpressBees News: Stay Informed on Logistics Innovations',
    description:
      'Keep up with the logistics innovations and updates at XpressBees. Explore our news section for valuable insights and trends in supply chain solutions.',
    canonical: 'https://www.xpressbees.com/News',
  },
  [PAGE_URL.ABOUT_US]: {
    title: 'XpressBees: Your Trusted Partner for Seamless Logistics Solutions',
    description:
      "Explore XpressBees' journey– a leader in logistics.From commitment to excellence to redefining the sector, join us as we redefine the logistics experience for businesses.",
    canonical: 'https://www.xpressbees.com/AboutUs',
  },
  [PAGE_URL.CAREER_PAGE]: {
    title: 'Join XpressBees - Explore Career Opportunities in Logistics',
    description:
      'Unlock your potential with XpressBees Careers. Explore diverse opportunities in logistics. Join a team valuing innovation, growth, and commitment to excellence.',
    canonical: 'https://www.xpressbees.com/Career',
  },
  [PAGE_URL.CONTACT_US]: {
    title: 'Contact XpressBees - Reach Out for Reliable Logistics Solutions',
    description:
      "Contact XpressBees for all your logistics needs: Inquiries, feedback, or partnerships—we're ready to assist. Experience seamless communication and reliable solutions.",
    canonical: 'https://www.xpressbees.com/ContactUs',
  },
};

// file for utility functions

/**
 * To check input string is valid JSON or not
 *
 * @param {string} input -input to check is valid JSON or not
 * @returns boolean - true, if valid else false
 */
export const isJson = (input) => {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Formats a date into a human-readable date string.
 *
 * @param {Date|string} date - The date to format. It can be a Date object or a valid date string.
 * @returns {string} A human-readable date string in the format "Month Day, Year".
 */
export const formatDate = (date) => {
  const tempDate = new Date(date);
  return `${tempDate.toLocaleDateString(undefined, {
    month: 'long',
  })} ${tempDate.getDate()}, ${tempDate.getFullYear()}`;
};

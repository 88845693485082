/**
 * To check current browser support session / local storage or not
 * @returns boolean - true, if support else false.
 */
export const isWebStorageSupported = () => {
  const sStorage = window.sessionStorage;
  const lStorage = window.localStorage;
  try {
    sStorage.setItem('test', 'test');
    sStorage.removeItem('test');
    lStorage.setItem('test', 'test');
    lStorage.removeItem('test');
    return true;
  } catch (e) {
    /* istanbul ignore next */
    return false;
  }
};

/**
 * Retrieve the value of a cookie by its name.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string|null} The value of the cookie with the specified name, or null if the cookie is not found.
 */
export const getCookie = (name) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split('; ');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split('=');
    if (cookie[0] === name) {
      return decodeURIComponent(cookie[1]);
    }
  }

  return null;
};

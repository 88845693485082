import { all, call, spawn } from 'redux-saga/effects';
import shipmentSaga from './shipmentSaga';
import blogSaga from './blogSaga';
import signupSaga from './signupSaga';

/**
 * add all the saga in the sagas array
 */
const sagas = [shipmentSaga, blogSaga, signupSaga];

export default function* rootSaga() {
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            console.error('Unexpected saga termination', error);
          }
        }
      })
    )
  );
}

import { call, put, takeLatest } from 'redux-saga/effects';
import SignupService from '../services/SignupService';
import { signupActions } from '../store/slices/signupSlice';

export const postSignupDetails = function* (action) {
  try {
    yield call(SignupService.postSignupDetails, action.payload);
    yield put(signupActions.signupDataSuccess());
  } catch (err) {
    yield put(signupActions.signupDataFailure());
  }
};

export default function* signupSaga() {
  yield takeLatest(signupActions.signupDataRequest.type, postSignupDetails);
}

import { createSlice } from '@reduxjs/toolkit';

// initial state for shipment slice
export const initialState = {
  isLoading: null,
  trackingData: [],
  trackingHistory: {},
};

const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    updateTrackingData: (state, action) => {
      state.trackingData = action.payload;
    },
    updateTrackingHistory: (state, action) => {
      state.trackingHistory = action.payload;
    },
    updateIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    fetchTrackingData: (state, action) => {},
    fetchTrackingHistory: (state, action) => {},
  },
});

export const shipmentActions = shipmentSlice.actions;
export const shipmentReducer = shipmentSlice.reducer;

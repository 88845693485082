import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * useOnRouteChange hook: To perform an action when the route changes.
 *
 * @namespace useOnRouteChange
 *
 * @returns invoked callback function
 */
const useOnRouteChange = (callback) => {
  const location = useLocation();
  useEffect(() => callback(location), [location, callback]);
};

export default useOnRouteChange;

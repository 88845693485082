import { useCallback } from 'react';
import { useIntl } from 'react-intl';

/**
 * useTranslation hook: To support react-intl multi language
 *
 * @namespace useTranslation
 *
 * @returns Function
 *
 * @example
 * translation('GREETING')
 * translation('GREETING', { user: 'Test' })
 * translation('GREETING', { user: 'Test' }, { defaultMessage: 'Hello {user}!' })
 * translation('GREETING', { user: 'Test' }, { description: 'Title for my component!', defaultMessage: 'Hello {user}!' })
 */
export const useTranslation = () => {
  const intl = useIntl();
  /**
   * id: id corresponding to the text to be translated from the translation files.
   * values: optional, only needed if positional value need to pass in translation corresponding to translation file
   * descriptors: optional, only need to pass for defaultMessage, description is needed.
   */
  const memoizedT = useCallback(
    (id, values, descriptors) =>
      intl.formatMessage({ id, ...descriptors }, values),
    [intl]
  );

  return memoizedT;
};

import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa';

/**
 * Returns an array of service checkboxes with translated labels and values.
 *
 * @returns {Array} An array of objects representing service checkboxes.
 */
export const getServicesCheckboxes = (t) => {
  return [
    { label: t('app.B2C_SERVICE'), value: t('app.B2C_SERVICE') },
    { label: t('app.B2B_SERVICE'), value: t('app.B2B_SERVICE') },
    { label: t('app.CROSS_BORDER'), value: t('app.CROSS_BORDER') },
    { label: t('app.3PL'), value: t('app.3PL') },
  ];
};

/**
 * Returns an object of signup messages with icons and translated text.
 *
 * First item is for success & second is for failure
 * @returns {Object} An object representing signup messages.
 */
export const getSignupMessages = (t) => {
  return {
    success: {
      icon: <FaCheckCircle color='green' className='message-icon' />,
      text: (
        <p className='message-paragraph'>
          {t('app.SIGNUP_SUCCESS_MESSAGE_1')}
          <br />
          <br />
          {t('app.SIGNUP_SUCCESS_MESSAGE_2')}
        </p>
      ),
    },
    fail: {
      icon: <FaTimesCircle color='red' className='message-icon' />,
      text: (
        <p className='message-paragraph'>
          {t('app.SIGNUP_FAIL_MESSAGE_1')}
          <br />
          <br />
          {t('app.SIGNUP_FAIL_MESSAGE_2')}
          <a href='mailto:salesenquiry@xpressbees.com'>
            salesenquiry@xpressbees.com
          </a>
          <br />
          <br />
          {t('app.SIGNUP_FAIL_MESSAGE_3')}
        </p>
      ),
    },
  };
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { StyledNavTop, TopbarInfo, TopbarInfoPara } from './Header.styled';
import Navbar from './Navbar/Navbar';
import { Link } from 'react-router-dom';
/**
 * Header component displaying a sticky navigation bar with a logo, marquee, and dropdown menu items.
 *
 * @component
 * @example
 * return (
 *   <Header />
 * )
 */

function Header() {
  const t = useTranslation();
  const [activeClass, setActiveClass] = useState('');
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setActiveClass('sticky');
      } else {
        setActiveClass('');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /**
   * Renders the marquee component displaying a scrolling message.
   *
   * @returns {JSX.Element} The marquee component.
   */

  const renderMarquee = () => {
    return <TopbarInfoPara>{t('app.HEADER_MARQUEE')}</TopbarInfoPara>;
  };

  /**
   * Renders the logo with both black and white logo images.
   *
   * @returns {JSX.Element} The logo component.
   */
  const renderLogo = () => {
    return (
      <Link className='navbar-brand' to='/'>
        <img
          src='/images/blackLogo.png'
          alt='ExpressBees Logo'
          className='blackLogo'
        />
        <img
          src='/images/whiteLogo.png'
          alt='ExpressBees Logo'
          className='whiteLogo'
        />
      </Link>
    );
  };

  return (
    <StyledNavTop>
      <div className={`${activeClass}`} data-testid='header-block'>
        <TopbarInfo>{renderMarquee()}</TopbarInfo>

        <nav className='navbar navbar-expand-lg bg-transparent'>
          <div className='container'>
            {renderLogo()}
            <Navbar />
          </div>
        </nav>
      </div>
    </StyledNavTop>
  );
}

export default Header;

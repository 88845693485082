import { SHIPMENT_API_ENDPOINT } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class ShipmentService {
  // function to fetch shipment tracking details
  async getShipmentTrackingDetails(payload) {
    const response = await HttpClient.post(
      SHIPMENT_API_ENDPOINT.GET_SHIPMENT_TRACKING_DETAILS,
      payload
    );
    return response;
  }

  //function to fetch history of a specific shipment
  async getShipmentHistory(payload) {
    const response = await HttpClient.get(
      SHIPMENT_API_ENDPOINT.GET_SHIPMENT_TRACKING_DETAILS +
        `/${payload.shippingId}`
    );
    return response;
  }
}

export default new ShipmentService();

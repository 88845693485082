import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  Background,
  ModalContent,
  ModalWrapper,
  CloseModalButton,
  FormBody,
  FormInput,
  FormRadioRow,
  FormRadioHeading,
  FormRadioCol,
  FormMobleCodeCol,
  FormMobleNumberCol,
  FormMobleRow,
  FormRow,
  FormTextarea,
  SubmitButton,
  FormHeadRow,
  FormInputFull,
  SignupLoader,
} from './SignUp.styled';
import { useRef, useState, useMemo } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { signupActions } from '../../store/slices/signupSlice';
import useAnalytics from '../../hooks/useAnalytics';
import { getServicesCheckboxes, getSignupMessages } from './SignUpConfig';
import { isValidEmail, isValidPhoneNumber } from '../../utils/validators';

/**
 * Renders a message with an icon and HTML-formatted text.
 *
 * @param {Object} messageItem - The message item to be rendered.
 * @param {string} messageItem.icon - The URL or source of the icon image.
 * @param {string} messageItem.text - The HTML-formatted text content of the message.
 * @returns {JSX.Element} JSX element representing the rendered message.
 */
const renderMessage = (messageItem) => {
  return (
    <div className='message-box'>
      {messageItem.icon}
      {messageItem.text}
    </div>
  );
};

/**
 * Renders checkboxes for services based on the provided checkboxes array and input change handler.
 *
 * @param {Array} checkboxes - An array of objects representing checkboxes.
 * @param {Object} checkboxes.item - An individual checkbox item.
 * @param {string} checkboxes.item.value - The value of the checkbox.
 * @param {string} checkboxes.item.label - The label for the checkbox.
 * @param {function} handleInputChange - Input change handler function.
 * @returns {JSX.Element} JSX for the rendered checkboxes.
 */
const renderServicesCheckboxes = (checkboxes, handleInputChange) => {
  return (
    <>
      {checkboxes.map((item) => (
        <FormRadioCol key={item.value}>
          <label>
            <input
              type='checkbox'
              value={item.value}
              onChange={handleInputChange}
              name='selectedServices'
            />
            <b>{item.label}</b>
          </label>
        </FormRadioCol>
      ))}
    </>
  );
};

/**
 * Renders an error message element for a given error message.
 *
 * @function
 * @param {string} message - The error message to be displayed.
 * @returns {JSX.Element} JSX for the error message.
 */
const renderInputError = (message) => {
  return <span className='error-message'>{message}</span>;
};

/**
 * SignUp Component
 *
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.showModal - Indicates whether the modal should be displayed
 * @param {function} props.setShowModal - Function to toggle the modal's visibility
 * @returns {JSX.Element} SignUp component JSX
 */

const SignUp = () => {
  const t = useTranslation();
  const initialFormData = {
    fullName: '',
    companyName: '',
    countryCode: '91',
    mobile: {
      value: '',
      valid: false,
    },
    location: '',
    email: {
      value: '',
      valid: false,
    },
    selectedServices: [],
    queryDetails: '',
    contactConsent: false,
    recaptcha: {
      value: '',
      valid: false,
    },
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmit, setIsSubmit] = useState(false);
  const captchaRef = useRef();
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const { signupModal, status, isLoading } = useSelector(
    (state) => state.signup
  );
  const signupMessages = useMemo(() => getSignupMessages(t), [t]);
  const checkboxes = useMemo(() => getServicesCheckboxes(t), [t]);

  // Handler function to close modal
  const handleCloseModal = (e) => {
    if (e.currentTarget === e.target) {
      setFormData(initialFormData);
      dispatch(signupActions.signupDataReset());
      dispatch(signupActions.hideSignupModal());
      setIsSubmit(false);
    }
  };

  // Input handler for all the form inputs
  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;

    switch (name) {
      case 'contactConsent':
        setFormData((prev) => ({
          ...prev,
          contactConsent: !prev.contactConsent,
        }));
        break;
      case 'selectedServices':
        if (checked) {
          setFormData((prev) => ({
            ...prev,
            selectedServices: [...prev.selectedServices, value],
          }));
          break;
        }
        setFormData((prev) => ({
          ...prev,
          selectedServices: prev.selectedServices.filter(
            (item) => item !== value
          ),
        }));
        break;
      case 'mobile':
        setFormData((prev) => ({
          ...prev,
          mobile: { value, valid: isValidPhoneNumber(value) },
        }));
        break;
      case 'email':
        setFormData((prev) => ({
          ...prev,
          email: { value, valid: isValidEmail(value) },
        }));
        break;
      default:
        setFormData((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  // Input handler for recaptcha box
  const handleCAPTCHA = (value) => {
    setFormData((prev) => ({ ...prev, recaptcha: { value, valid: true } }));
  };

  // Handler function for form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      countryCode,
      mobile,
      email,
      recaptcha,
      selectedServices,
      location,
      queryDetails,
      ...payload
    } = formData;
    if (selectedServices.length > 0 && mobile.valid && recaptcha.valid) {
      const updatedPayload = {
        ...payload,
        phone: `+${countryCode}${mobile.value}`,
        recaptchaValue: recaptcha.value,
        selectedServices,
        ...(email.value && { email: email.value }),
        ...(location && { location }),
        ...(queryDetails && { queryDetails }),
      };
      dispatch(signupActions.signupDataRequest(updatedPayload));
      trackEvent('SignUpEvent', updatedPayload);
    }
  };

  return (
    <>
      {signupModal && (
        <Background onClick={handleCloseModal}>
          <ModalWrapper>
            <ModalContent>
              {isLoading ? (
                <SignupLoader data-testid='signup-loader'>
                  <img src='/images/Loader.gif' />
                </SignupLoader>
              ) : status === null ? (
                <form onSubmit={handleSubmit} data-testid='signup-form'>
                  <FormBody>
                    <FormHeadRow>
                      <h3>{t('app.SIGNUP_FORM_HEADING')}</h3>
                      <p>{t('app.SIGNUP_FORM_DESCRIPTION')}</p>
                    </FormHeadRow>
                    <FormRow>
                      <FormInput>
                        <input
                          className='form-control-plaintext'
                          type='text'
                          placeholder={t('app.SIGNUP_NAME_PLACEHOLDER')}
                          name='fullName'
                          value={formData.fullName}
                          required
                          onChange={handleInputChange}
                        />
                      </FormInput>
                      <FormInput>
                        <input
                          className='form-control-plaintext'
                          type='text'
                          placeholder={t('app.SIGNUP_COMPANY_PLACEHOLDER')}
                          name='companyName'
                          required
                          value={formData.companyName}
                          onChange={handleInputChange}
                        />
                      </FormInput>
                    </FormRow>
                    <FormRow>
                      <FormInput>
                        <FormMobleRow>
                          <FormMobleCodeCol>
                            <PhoneInput
                              country={'in'}
                              inputClass='smaller-input'
                              value={formData.countryCode}
                              onChange={(_, __, e) => handleInputChange(e)}
                              inputProps={{
                                disabled: true,
                                name: 'countryCode',
                              }}
                            />
                          </FormMobleCodeCol>
                          <FormMobleNumberCol>
                            <input
                              className='form-control-plaintext'
                              type='text'
                              placeholder={t('app.SIGNUP_MOBILE_PLACEHOLDER')}
                              name='mobile'
                              value={formData.mobile.value}
                              required
                              onChange={handleInputChange}
                            />
                            {!formData.mobile.valid &&
                              formData.mobile.value?.length > 0 &&
                              renderInputError(t('app.SIGNUP_MOBILE_ERROR'))}
                          </FormMobleNumberCol>
                        </FormMobleRow>
                      </FormInput>
                      <FormInput>
                        {' '}
                        <input
                          className='form-control-plaintext'
                          type='email'
                          placeholder={t('app.SIGNUP_EMAIL_PLACEHOLDER')}
                          name='email'
                          value={formData.email.value}
                          onChange={handleInputChange}
                        />
                        {!formData.email.valid &&
                          formData.email.value?.length > 0 &&
                          renderInputError(t('app.SIGNUP_EMAIL_ERROR'))}
                      </FormInput>
                    </FormRow>

                    <FormRadioRow>
                      <FormRadioHeading>
                        {t('app.BELOW_BOX')}
                        {formData.selectedServices.length === 0 &&
                          isSubmit &&
                          renderInputError(
                            t('app.SIGNUP_SELECTED_SERVICES_ERROR')
                          )}
                      </FormRadioHeading>
                    </FormRadioRow>
                    <FormRadioRow>
                      {renderServicesCheckboxes(checkboxes, handleInputChange)}
                      <FormRadioCol>
                        <span>
                          ?<p>{t('app.SIGN_UP')}</p>
                        </span>
                      </FormRadioCol>
                    </FormRadioRow>
                    <FormRadioRow>
                      <FormRadioHeading>
                        {t('app.SIGNUP_LOCATION_LABEL')}
                      </FormRadioHeading>
                    </FormRadioRow>
                    <FormRow>
                      <FormInputFull>
                        <input
                          className='form-control-plaintext'
                          type='text'
                          placeholder={t('app.SIGNUP_LOCATION_PLACEHOLDER')}
                          name='location'
                          value={formData.location}
                          onChange={handleInputChange}
                        />
                      </FormInputFull>
                    </FormRow>
                    <FormRow>
                      <FormTextarea>
                        <textarea
                          className='form-control-plaintext'
                          type='textarea'
                          rows='2'
                          cols='100'
                          name='queryDetails'
                          onChange={handleInputChange}
                          value={formData.queryDetails}
                          placeholder={t('app.SIGNUP_QUERY_PLACEHOLDER')}
                        ></textarea>
                      </FormTextarea>
                    </FormRow>
                    <FormRadioRow>
                      <FormRadioHeading>
                        {!formData.recaptcha.valid && (
                          <p>{t('app.RECAPTCH')}</p>
                        )}
                        {!formData.recaptcha.valid &&
                          isSubmit &&
                          renderInputError(t('app.SIGNUP_RECAPTHCA_ERROR'))}
                      </FormRadioHeading>
                    </FormRadioRow>
                    <FormRow>
                      <div className='reCaptcha' ref={captchaRef}>
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_SITE_KEY}
                          onChange={(value) => handleCAPTCHA(value)}
                          name='recaptcha'
                          data-testid='signup-recaptcha'
                          required
                        />
                      </div>
                    </FormRow>
                    <FormRadioRow>
                      <FormRadioCol>
                        <label>
                          <input
                            type='checkbox'
                            name='contactConsent'
                            onChange={handleInputChange}
                            required
                          />
                          <b>{t('app.CONSENT')}</b>
                        </label>
                      </FormRadioCol>
                    </FormRadioRow>

                    <FormRow>
                      <SubmitButton
                        type='submit'
                        onClick={() => setIsSubmit(true)}
                      >
                        {t('app.SUBMIT')}
                      </SubmitButton>
                    </FormRow>
                  </FormBody>
                </form>
              ) : status ? (
                renderMessage(signupMessages.success)
              ) : (
                renderMessage(signupMessages.fail)
              )}
            </ModalContent>
            <CloseModalButton
              aria-label='Close modal'
              onClick={handleCloseModal}
              data-testid='closeModalButton'
            />
          </ModalWrapper>
        </Background>
      )}
    </>
  );
};

export default SignUp;

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/Routes';
import store from './store/store';
import GlobalStyle from './styles/GlobalStyle';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './config/appConfig';
import useAnalytics from './hooks/useAnalytics';

const App = () => {
  const { init } = useAnalytics();
  init();
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ScrollToTop />
        <GlobalStyle />
        <Routes />
        <ToastContainer />
      </Provider>
    </BrowserRouter>
  );
};

export default App;

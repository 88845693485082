import React, { useEffect, useState } from 'react';
import { FeatureFlagsContext } from '../contexts/FeatureFlagsContext';

const loadFeatureFlags = async () => {
  const flags = (await import(`../config/feature-flag.json`)).default;
  const environment = process.env.REACT_APP_ENVIRONMENT || 'PRODUCTION';
  return flags[environment] || {};
};

const FeatureFlagsProvider = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState({});
  useEffect(() => {
    (async () => {
      try {
        const feature = await loadFeatureFlags();
        setFeatureFlags(feature);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsProvider;

import get from 'lodash/get';
import { call, put, takeLatest } from 'redux-saga/effects';
import { BlogActionTypes, blogActions } from '../store/slices/blogSlice';
import BlogService from '../services/BlogService';
import { errorActions } from '../store/slices/errorSlice';

// saga handler to fetch latest blogs and update redux store as per the api payload
export const fetchLatestBlogs = function* (action) {
  try {
    const response = yield call(BlogService.getLatestBlogs, action.payload);
    const latestBlogsResponse = yield get(response, 'data');
    yield put(blogActions.updateLatestBlogs(latestBlogsResponse));
  } catch (err) {
    yield put(errorActions.setError(err.message));
    yield put(errorActions.clearError());
  }
};

// saga handler to fetch specific category blogs and update redux store as per the api payload
export const fetchCategoryBlogs = function* (action) {
  try {
    const response = yield call(BlogService.getCategoryBlogs, action.payload);
    const categoryBlogsResponse = yield get(response, 'data');
    yield put(blogActions.updateCategoryBlogs(categoryBlogsResponse[0]));
  } catch (err) {
    yield put(errorActions.setError(err.message));
    yield put(errorActions.clearError());
  }
};

// saga handler to fetch specific category wise blogs and update redux store as per the api payload
export const fetchCategoryWiseBlogs = function* (action) {
  try {
    const response = yield call(BlogService.getCategoryBlogs, action.payload);
    const categoryWiseBlogsResponse = yield get(response, 'data');
    yield put(blogActions.updateCategoryWiseBlogs(categoryWiseBlogsResponse));
  } catch (err) {
    yield put(errorActions.setError(err.message));
    yield put(errorActions.clearError());
  }
};

// saga handler to fetch a single blog and update redux store as per the api payload
export const fetchSingleBlog = function* (action) {
  try {
    const response = yield call(BlogService.getSingleBlog, action.payload);
    const singleBlogResponse = yield get(response, 'data');
    yield put(blogActions.updateSingleBlog(singleBlogResponse));
  } catch (err) {
    yield put(errorActions.setError(err.message));
    yield put(errorActions.clearError());
  }
};

// saga handler to fetch categories and update redux store as per the api payload
export const fetchCategories = function* () {
  try {
    const response = yield call(BlogService.getCategories);
    const categoriesResponse = yield get(response, 'data');
    yield put(blogActions.updateCategories(categoriesResponse));
  } catch (err) {
    yield put(errorActions.setError(err.message));
    yield put(errorActions.clearError());
  }
};

export default function* blogSaga() {
  yield takeLatest(BlogActionTypes.FETCH_LATEST_BLOGS, fetchLatestBlogs);
  yield takeLatest(BlogActionTypes.FETCH_CATEGORY_BLOGS, fetchCategoryBlogs);
  yield takeLatest(BlogActionTypes.FETCH_CATEGORIES, fetchCategories);
  yield takeLatest(BlogActionTypes.FETCH_SINGLE_BLOG, fetchSingleBlog);
  yield takeLatest(
    BlogActionTypes.FETCH_CATEGORY_WISE_BLOGS,
    fetchCategoryWiseBlogs
  );
}

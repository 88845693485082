import styled from 'styled-components';
import { DEVICES } from '../../config/appConfig';
export const TopbarInfo = styled.div`
  overflow: hidden;
  position: relative;
  background: #000;
  color: #fff;
  font-size: 16px;
  height: 44px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media ${DEVICES.mobile} {
    height: 34px;
  }
`;
export const TopbarInfoPara = styled.p`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 44px;
  text-align: center;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 2s linear infinite;
  -webkit-animation: scroll-left 2s linear infinite;
  animation: scroll-left 20s linear infinite;
  @-moz-keyframes scroll-left {
    0% {
      -moz-transform: translateX(100%);
    }
    100% {
      -moz-transform: translateX(-100%);
    }
  }
  @-webkit-keyframes scroll-left {
    0% {
      -webkit-transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(-100%);
    }
  }
  @keyframes scroll-left {
    0% {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    100% {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
  @media ${DEVICES.tablet} {
    width: 170%;
    -moz-transform: translateX(170%);
    -webkit-transform: translateX(170%);
    transform: translateX(170%);
    animation: scroll-left 20s linear infinite;
  }
  @media ${DEVICES.mobile} {
    width: 170%;
    line-height: 34px;
    -moz-transform: translateX(170%);
    -webkit-transform: translateX(170%);
    transform: translateX(170%);
    animation: scroll-left 20s linear infinite;
  }
  @media ${DEVICES.sMobile} {
    width: 330%;
    line-height: 34px;
    -moz-transform: translateX(330%);
    -webkit-transform: translateX(330%);
    transform: translateX(330%);
    animation: scroll-left 30s linear infinite;
  }
`;
export const StyledNavTop = styled.div`
  width: 100%;
  display: block;
  position: absolute;
  z-index: 10;
  .sticky {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1);
    background: #fff;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }

  .navbar .container a.navbar-brand img {
    display: block;
    width: 220px;
    transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1);
  }
  .navbar .container a.navbar-brand img.blackLogo {
    display: none;
    width: 220px;
    transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1);
  }
  .sticky .navbar .container a.navbar-brand img {
    display: none;
  }
  .sticky .navbar .container a.navbar-brand img.blackLogo {
    display: block;
  }
  .navbar-nav .nav-link,
  .navbar-nav .show > .nav-link {
    color: #fff;
    font-weight: 500;
    font-size: 15px;
  }
  .sticky .navbar-nav .nav-link,
  .navbar-nav .show > .nav-link {
    color: #000;
  }
  .navbar-nav {
    gap: 5px;
  }
  .track-shipment {
    border-radius: 8px;
    border-bottom: 3px solid var(--orange-orange-70, #ac5407);
    background: var(--orange-orange, #f58220);
  }
  .sticky .track-shipment a.nav-link {
    color: #fff;
  }
  .dropdown-menu {
    min-width: 220px;
  }
  .dropdown-menu li a {
    margin: 10px;
    width: 92%;
    text-align: center;
    border-bottom: solid 1px #f4f4f4;
    background: transparent;
  }

  @media ${DEVICES.tablet} {
    .navbar .navbar-collapse {
      background: #000000;
      padding-left: 10px;
      padding-bottom: 10px;
    }
    .sticky .navbar .navbar-collapse {
      background: #ffffff;
      padding-left: 10px;
    }
    .navbar .navbar-toggler {
      border-color: #ffffff00;
      -webkit-filter: grayscale(1) invert(1);
      filter: grayscale(1) invert(1);
    }
    .sticky .navbar .navbar-toggler {
      margin-right: 0px;
      -webkit-filter: grayscale(0) invert(0);
      filter: grayscale(0) invert(0);
    }
    .navbar-nav {
      gap: 0px;
    }
    .navbar .container a.navbar-brand img,
    .navbar .container a.navbar-brand img.blackLogo {
      width: 200px;
    }
    .track-shipment {
      border-radius: 8px;
      border-bottom: 3px solid var(--orange-orange-70, #ac5407);
      background: var(--orange-orange, #f58220);
      width: 200px;
      padding-left: 10px;
    }
    .sticky .track-shipment a.nav-link {
      color: #fff;
      display: inline-block;
    }
    .ShopNowMobile {
      border-radius: 8px;
      border-bottom: 3px solid #d1d1d1;
      background: #8c8c8c;
    }
    .sticky .ShopNowMobile a.nav-link {
      color: #fff;
      display: inline-block;
    }
    .track-shipment {
      border-radius: 8px;
      border-bottom: 3px solid var(--orange-orange-70, #ac5407);
      background: var(--orange-orange, #f58220);
      width: 200px;
      padding-left: 10px;
    }
  }
  @media ${DEVICES.mobile} {
    .sticky {
      padding-top: 0px;
    }
    .navbar-nav {
      gap: 0px;
    }
    .navbar .container a.navbar-brand img {
      width: 180px;
    }
    .sticky .navbar .container a.navbar-brand img.blackLogo {
      width: 180px;
    }
    .navbar .navbar-toggler {
      border-color: #ffffff00;
      -webkit-filter: grayscale(1) invert(1);
      filter: grayscale(1) invert(1);
    }
    .sticky .navbar .navbar-toggler {
      margin-right: 0px;
      -webkit-filter: grayscale(0) invert(0);
      filter: grayscale(0) invert(0);
    }
    .navbar .navbar-collapse {
      background: #000000;
      padding-left: 10px;
      padding-bottom: 10px;
    }
    .sticky .navbar .navbar-collapse {
      background: #ffffff;
      padding-left: 10px;
    }

    .dropdown-menu {
      top: 35px;
    }
    .dropdown:hover .dropdown-menu {
      display: block;
      margin-top: 0.125em;
    }
    .track-shipment {
      border-radius: 8px;
      border-bottom: 3px solid var(--orange-orange-70, #ac5407);
      background: var(--orange-orange, #f58220);
      padding-right: 10px;
      display: inline-block;
      padding-left: 10px;
    }
    .sticky .track-shipment a.nav-link {
      color: #fff;
      display: inline-block;
    }
    a.ship-now-button {
      border-radius: 8px;
      border-bottom: 3px solid #d1d1d1;
      background: #8c8c8c;
      padding-right: 10px;
      display: inline-block;
      padding-left: 10px;
      color: #fff;
      margin-top: 10px;
    }
    .sticky a.ship-now-button {
      color: #fff !important;
    }
  }
  @media ${DEVICES.sMobile} {
    .track-shipment {
      display: inline-block;
      width: 150px;
    }
    a.ship-now-button {
      border-radius: 8px;
      border-bottom: 3px solid #d1d1d1;
      background: #8c8c8c;
      padding-right: 10px;
      display: inline-block;
      padding-left: 10px;
      color: #fff;
      margin-top: 10px;
    }
    .sticky a.ship-now-button {
      color: #fff !important;
    }
  }
  @media ${DEVICES.tablet && DEVICES.laptop && DEVICES.desktop} {
    .ship-now-button {
      display: none;
    }
  }
  @media ${DEVICES.sMobile} {
    .sticky {
      padding-top: 0px;
    }
    .navbar-nav {
      gap: 0px;
    }
    .navbar .container a.navbar-brand img {
      width: 180px;
    }
    .sticky .navbar .container a.navbar-brand img.blackLogo {
      width: 180px;
    }
    .navbar .navbar-toggler {
      border-color: #ffffff00;
      -webkit-filter: grayscale(1) invert(1);
      filter: grayscale(1) invert(1);
    }
    .sticky .navbar .navbar-toggler {
      margin-right: 0px;
      -webkit-filter: grayscale(0) invert(0);
      filter: grayscale(0) invert(0);
    }
    .navbar .navbar-collapse {
      background: #000000;
      padding-left: 10px;
      padding-bottom: 10px;
    }
    .sticky .navbar .navbar-collapse {
      background: #ffffff;
      padding-left: 10px;
    }

    .dropdown-menu {
      top: 35px;
    }
    .dropdown:hover .dropdown-menu {
      display: block;
      margin-top: 0.125em;
    }
    .track-shipment {
      border-radius: 8px;
      border-bottom: 3px solid var(--orange-orange-70, #ac5407);
      background: var(--orange-orange, #f58220);
      padding-right: 10px;
      display: inline-block;
      padding-left: 10px;
    }
    .sticky .track-shipment a.nav-link {
      color: #fff;
      display: inline-block;
    }
    a.ship-now-button {
      border-radius: 8px;
      border-bottom: 3px solid #d1d1d1;
      background: #8c8c8c;
      padding-right: 10px;
      display: inline-block;
      padding-left: 10px;
      color: #fff;
      margin-top: 10px;
    }
    .sticky a.ship-now-button {
      color: #fff !important;
    }
  }
  @media ${DEVICES.sMobile} {
    .track-shipment {
      display: inline-block;
      width: 150px;
    }
    a.ship-now-button {
      border-radius: 8px;
      border-bottom: 3px solid #d1d1d1;
      background: #8c8c8c;
      padding-right: 10px;
      display: inline-block;
      padding-left: 10px;
      color: #fff;
      margin-top: 10px;
    }
    .sticky a.ship-now-button {
      color: #fff !important;
    }
  }
  @media ${DEVICES.tablet && DEVICES.laptop && DEVICES.desktop} {
    .ship-now-button {
      display: none;
    }
  }
`;

import ReactGA from 'react-ga4';
/**
 * Module for handling Google Analytics events and page views.
 * @module useAnalytics
 */

const useAnalytics = () => {
  const init = () => {
    const Google_Analytics_4_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    ReactGA.initialize(Google_Analytics_4_ID);
  };

  const trackPageView = (payload) => {
    ReactGA.send({
      hitType: 'pageview',
      page: payload.page,
      title: payload.title,
    });
  };

  const trackEvent = (eventName, payload) => {
    ReactGA.event(eventName, payload);
  };

  return { init, trackPageView, trackEvent };
};
export default useAnalytics;

import Storage from '../helpers/Storage';

const RTL_SCRIPTS = new Set([
  'Arab',
  'Syrc',
  'Samr',
  'Mand',
  'Thaa',
  'Mend',
  'Nkoo',
  'Adlm',
  'Rohg',
  'Hebr',
]);

const RTL_LANGS = new Set([
  'ae',
  'ar',
  'arc',
  'bcc',
  'bqi',
  'ckb',
  'dv',
  'fa',
  'glk',
  'he',
  'ku',
  'mzn',
  'nqo',
  'pnb',
  'ps',
  'sd',
  'ug',
  'ur',
  'yi',
]);

/**
 * Determines if a locale is read right to left using [Intl.Locale]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale}.
 */
export const isRTL = (locale) => {
  // If the Intl.Locale API is available, use it to get the script for the locale.
  // This is more accurate than guessing by language, since languages can be written in multiple scripts.
  if (locale && Intl.Locale) {
    let script = new Intl.Locale(locale).maximize().script;
    return RTL_SCRIPTS.has(script);
  }

  // If not, just guess by the language (first part of the locale)
  let lang = locale.split('-')[0];
  return RTL_LANGS.has(lang);
};

export const getDefaultLocale = () => {
  /* istanbul ignore next */
  let locale =
    (typeof navigator !== 'undefined' && navigator.language) ||
    (navigator.languages && navigator.languages[0]) ||
    'en-US';
  try {
    Intl.DateTimeFormat.supportedLocalesOf(locale);
  } catch (error) {
    /* istanbul ignore next */
    locale = 'en-US';
  }

  // get locale preferences if any exist
  const localePreference = Storage.getItem('locale');
  /* istanbul ignore if */
  if (
    localePreference !== null &&
    typeof localePreference === 'object' &&
    localePreference.hasOwnProperty('locale')
  ) {
    return localePreference;
  } else {
    return {
      locale,
      direction: isRTL(locale) ? 'rtl' : 'ltr',
    };
  }
};

export const getLocaleInfo = (lang) => {
  let locale = null;
  try {
    locale = new Intl.Locale(lang).baseName;
  } catch (error) {
    locale = 'en-US';
  }
  return {
    locale,
    direction: isRTL(locale) ? 'rtl' : 'ltr',
  };
};

// Keep all the api related configs such as endpoints in this file

// shipment api endpoints
export const SHIPMENT_API_ENDPOINT = {
  GET_SHIPMENT_TRACKING_DETAILS: '/api/tracking',
};

// blog api endpoints
export const BLOG_API_ENDPOINT = {
  GET_BLOG_LISTINGS: '/api/blogs',
  GET_CATEGORIES: '/api/categories',
};

export const SIGNUP_API_ENDPOINT = {
  SIGNUP: '/api/signup',
};

import React, { useContext } from 'react';

/**
 * LanguageContext: to provide language list
 *
 * @namespace LanguageContext
 */
export const LanguageContext = React.createContext();

export const useLanguageContext = () => useContext(LanguageContext);

import React from 'react';
import { render } from 'react-dom';
import App from './App';
import FeatureFlagsProvider from './providers/FeatureFlagsProvider';
import LanguageProvider from './providers/LanguageProvider'; // Import LanguageProvider
import I18nProvider from './providers/I18nProvider';
import './wdyr';

const renderApp = () =>
  render(
    <React.StrictMode>
      <FeatureFlagsProvider>
        <LanguageProvider>
          <I18nProvider>
            <App />
          </I18nProvider>
        </LanguageProvider>
      </FeatureFlagsProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
renderApp();

import get from 'lodash/get';
import { call, put, takeLatest } from 'redux-saga/effects';
import ShipmentService from '../services/ShipmentService';
import { shipmentActions } from '../store/slices/shipmentSlice';
import { errorActions } from '../store/slices/errorSlice';

// saga handler to fetch tracking details and update redux store as per the api payload
export const fetchTrackingDetails = function* (action) {
  try {
    yield put(shipmentActions.updateIsLoading(true));
    const response = yield call(
      ShipmentService.getShipmentTrackingDetails,
      action.payload
    );
    const trackingDetailsResponse = yield get(response, 'data');
    yield put(shipmentActions.updateTrackingData(trackingDetailsResponse));
  } catch (err) {
    if (err.response?.status === 400 || err.response?.status === 404) {
      yield put(errorActions.setError(err.response?.data.message));
    } else {
      yield put(errorActions.setError(err.response?.data.message));
    }
    yield put(errorActions.clearError());
  } finally {
    yield put(shipmentActions.updateIsLoading(false));
  }
};

export const fetchTrackingHistory = function* (action) {
  try {
    const response = yield call(
      ShipmentService.getShipmentHistory,
      action.payload
    );
    const trackingHistoryResponse = yield get(response, 'data');
    yield put(shipmentActions.updateTrackingHistory(trackingHistoryResponse));
  } catch (err) {
    if (err.status === 400) {
      yield put(errorActions.setError(err.response.data.message));
    } else {
      yield put(errorActions.setError(err.response.data.message));
    }
    yield put(errorActions.clearError());
  }
};

export default function* shipmentSaga() {
  yield takeLatest(
    shipmentActions.fetchTrackingData.type,
    fetchTrackingDetails
  );
  yield takeLatest(
    shipmentActions.fetchTrackingHistory.type,
    fetchTrackingHistory
  );
}

import { BLOG_API_ENDPOINT } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class BlogService {
  // function to fetch latest blog listings
  async getLatestBlogs(payload) {
    const reqBody = { limit: payload.limit };
    const response = await HttpClient.post(
      BLOG_API_ENDPOINT.GET_BLOG_LISTINGS,
      reqBody
    );
    return response;
  }

  // function to fetch category blog listings
  async getCategoryBlogs(payload) {
    const reqBody = { limit: payload.limit, categories: payload.categories };
    const response = await HttpClient.post(
      BLOG_API_ENDPOINT.GET_BLOG_LISTINGS,
      reqBody
    );
    return response;
  }

  // function to fetch a single blog
  async getSingleBlog(payload) {
    const response = await HttpClient.get(
      BLOG_API_ENDPOINT.GET_BLOG_LISTINGS + `/${payload.blogId}`
    );
    return response;
  }

  // function to fetch categories`
  async getCategories() {
    const response = await HttpClient.get(BLOG_API_ENDPOINT.GET_CATEGORIES);
    return response;
  }
}

export default new BlogService();

import { combineReducers } from '@reduxjs/toolkit';
import { shipmentReducer } from './slices/shipmentSlice';
import { blogReducer } from './slices/blogSlice';
import { errorReducer } from './slices/errorSlice';
import { signupReducer } from './slices/signupSlice';

const appReducer = combineReducers({
  shipment: shipmentReducer,
  blogs: blogReducer,
  error: errorReducer,
  signup: signupReducer,
});

/**
 * To initialize the combined state
 */
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;

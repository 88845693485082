import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import '../Header.styled';
import { getNavData } from './NavbarConfig';
import { Link } from 'react-router-dom';

/**
 * Navbar component that displays a navigation bar with optional dropdown menus.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.t - A translation function for translating text.
 * @returns {JSX.Element} - The rendered Navbar component.
 */

const Navbar = () => {
  const t = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleButtonClick = () => {
    setMenuOpen(false);
  };

  const handleDocumentClick = (event) => {
    if (!menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('scroll', handleDocumentClick);
      document.addEventListener('click', handleDocumentClick);
    } else {
      document.removeEventListener('scroll', handleDocumentClick);
      document.removeEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('scroll', handleDocumentClick);
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [menuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <button
        className='navbar-toggler'
        type='button'
        data-bs-toggle='collapse'
        data-bs-target='#navbarSupportedContent'
        aria-controls='navbarSupportedContent'
        aria-expanded={menuOpen}
        onClick={toggleMenu}
        aria-label='Toggle navigation'
        data-testid='navbar-button'
      >
        <span className='navbar-toggler-icon' />
      </button>
      <div
        className={`collapse navbar-collapse justify-content-end ${
          menuOpen ? 'show' : ''
        }`}
        id='navbarSupportedContent'
        data-testid='Navbar-block'
        ref={menuRef}
      >
        <ul className='navbar-nav'>
          {getNavData(t).map((navItem, index) => (
            <li
              key={index}
              onClick={() => {
                if (!navItem.children) {
                  handleButtonClick(index);
                }
              }}
              className={`nav-item ${navItem.children ? 'dropdown' : ''} ${
                navItem.className ? navItem.className : ''
              }`}
            >
              <Link
                className={`nav-link  ${
                  navItem.children ? 'dropdown-toggle' : ''
                }`}
                role='button'
                data-bs-toggle={navItem.children ? 'dropdown' : null}
                aria-expanded='false'
                to={navItem.href}
              >
                {navItem.label}
              </Link>
              {navItem.children && (
                <ul className='dropdown-menu'>
                  {navItem.children.map((dropDownItem, index) => (
                    <li key={index}>
                      <Link
                        className='dropdown-item'
                        onClick={() => handleButtonClick(index)}
                        to={dropDownItem.href}
                        target={dropDownItem.target}
                      >
                        {dropDownItem.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Navbar;

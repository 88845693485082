import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { DEVICES } from '../../config/appConfig';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalWrapper = styled.div`
  padding: 15px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10001;
  border-radius: 10px;
  width: 70%;

  @media ${DEVICES.sMobile} {
    padding: 15px 0px;
    margin: 10px;
    width: 92%;
  }
`;

export const ModalContent = styled.div`
  padding-top: 20px;
  .error-message {
    font-size: 14px;
    color: red;
  }

  .message-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .message-paragraph {
    text-align: center;
  }

  .message-icon {
    height: 50px;
    width: 50px;
    margin-bottom: 25px;
  }

  @media ${DEVICES.sMobile} {
    padding: 10px;
    max-height: 500px;
    overflow-y: auto;

    .message-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 30px;
      text-align: center;
    }
  }
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 5px;
  width: 24px;
  height: 24px;
  padding: 0;
  background: #f4f4f4;
  border-radius: 50%;
  z-index: 10;
  @media ${DEVICES.sMobile} {
    top: 10px;
    right: 4px;
    width: 22px;
    height: 22px;
  }
`;
export const FormBody = styled.div`
  width: 100%;
  font-size: 14px;
  p {
    margin-bottom: 0px;
  }
  @media ${DEVICES.sMobile} {
    padding-top: 20px;
  }
`;
export const FormRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  .reCaptcha {
    padding-left: 10px;
  }

  @media ${DEVICES.sMobile} {
    width: 100%;
    display: block;
  }
`;
export const FormHeadRow = styled.div`
  padding-left: 12px;
  h3 {
    color: #f58220;
    display: block;
    font-size: 24px;
    margin: 0px;
  }
  p {
    width: 100%;
    margin-bottom: 10px;
  }
`;
export const FormInputFull = styled.div`
  width: 100%;
  padding: 0px 10px;
  margin-bottom: 0px;
  input {
    box-shadow: 0px 2px 0px rgb(247, 148, 29);
    border-color: #f4f4f4;
    background: #ececec;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  @media ${DEVICES.sMobile} {
    width: 100%;
  }
`;
export const FormInput = styled.div`
  width: 50%;
  padding: 0px 10px;
  margin-bottom: 0px;
  input {
    box-shadow: 0px 2px 0px rgb(247, 148, 29);
    border-color: #f4f4f4;
    background: #ececec;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  @media ${DEVICES.sMobile} {
    width: 100%;
  }
`;
export const FormTextarea = styled.div`
  width: 100%;
  padding: 0px 10px;
  textarea {
    box-shadow: 0px 2px 0px rgb(247, 148, 29);
    border-color: #f4f4f4;
    background: #ececec;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
    width: 100%;
    resize: none;
  }
`;
export const FormMobleRow = styled.div`
  width: 100%;
  display: flex;
`;
export const FormMobleCodeCol = styled.div`
  width: 28%;
  margin-right: 2%;
  .react-tel-input {
    .form-control.smaller-input {
      width: 100%;
      background: #ececec;
      border: none;
      cursor: text;
      color: #8c8c8c;
    }
    .flag-dropdown {
      border: none;
      background: #ececec;
    }
    .flag-dropdown.open,
    .flag-dropdown.open .selected-flag {
      background: #ececec;
    }
  }
`;
export const FormMobleNumberCol = styled.div`
  width: 70%;
`;
export const FormRadioRow = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  padding: 0px 1.2%;
  @media ${DEVICES.sMobile} {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    margin-left: 10px;
    font-size: 14px;
  }
`;
export const FormRadioHeading = styled.div`
  width: 100%;
  @media ${DEVICES.sMobile} {
  }
`;
export const FormRadioCol = styled.div`
  margin-bottom: 0px;
  label {
    b {
      padding: 0px 5px;
      font-weight: 400;
      margin-right: 10px;
    }
  }
  span {
    height: 20px;
    width: 20px;
    line-height: 18px;
    border-radius: 50%;
    background: #ececec;
    display: inline-block;
    text-align: center;
    font-weight: 700;
    position: relative;
    &:hover p {
      opacity: 1;
      z-index: 1;
      top: -20px;
      display: block;
    }
    p {
      position: absolute;
      width: 150px;
      opacity: 0;
      background: #f4f4f4;
      left: 24px;
      top: 80px;
      z-index: -10;
      font-weight: 400;
      padding: 5px;
      border-radius: 5px;
      font-size: 10px;
      display: none;
      line-height: 1;
    }
  }
  @media ${DEVICES.sMobile} {
    span:hover p {
      opacity: 1;
      z-index: 1;
      top: -64px;
      left: -56px;
    }
  }
`;
export const PopupBx = styled.div`
  .modal {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(77, 77, 77, 0.7);
    transition: all 0.4s;
  }

  .modal:target {
    visibility: visible;
    opacity: 1;
  }

  .modal__content {
    border-radius: 4px;
    position: relative;
    max-width: 90%;
    background: #fff;
    padding: 1em 2em;
  }

  .modal__close {
    position: absolute;
    top: -40px;
    right: -40px;
    color: #585858;
    text-decoration: none;
  }
`;
export const SubmitButton = styled.button`
  box-shadow: 0px 2px 0px #ac5407;
  background: #f58220 !important;
  padding: 5px 10px !important;
  border-radius: 5px;
  border-color: rgb(247, 148, 29);
  color: #fff;
  margin-left: 10px;
  @media ${DEVICES.sMobile} {
    width: 95%;
    text-align: center;
  }
`;

export const SignupLoader = styled.div`
  position: relative;
  max-width: 1170px;
  width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
`;

import { isJson } from '../utils/utils';
import { isWebStorageSupported } from '../utils/web-storage';

/**
 * Storage Class: To set, get, remove and clear items form web (session/local) storage.
 *
 * @namespace Storage
 */
class Storage {
  // To initialize the storage type.
  #sessionStorage = null;
  #localStorage = null;

  constructor() {
    // Check if current browser support session and local storage or not.
    if (isWebStorageSupported()) {
      this.#sessionStorage = window.sessionStorage;
      this.#localStorage = window.localStorage;
    } else {
      console.warn(
        'Some features are missing in your browser, please upgrade your browser.'
      );
    }
  }

  /**
   *
   * @param {string} key - key for the session or local storage
   * @param {number | string | object | Array | boolean} value - value to be stored
   * @param {boolean} toSessionStorage - true, if want to store in session storage else false to store in local storage
   * @returns undefined
   */
  setItem(key, value, toSessionStorage = false) {
    // If key is not valid, don't store to the web storage/
    if (!this.isValidKey(key)) {
      return;
    }
    // format value, if boolean, array or object, converting to string
    value = this.formatValue(value);
    // if toSessionStorage = true, store in session storage else in localStorage
    if (toSessionStorage) {
      this.#sessionStorage.setItem(key, value);
    } else {
      this.#localStorage.setItem(key, value);
    }
  }

  /**
   *
   * @param {string} key - To get the value form web (session/local) storage
   * @returns value | undefined
   */
  getItem(key) {
    // If key is not valid, return
    if (!this.isValidKey(key)) {
      return;
    }
    // Get value from session or local storage
    let value =
      this.#sessionStorage.getItem(key) || this.#localStorage.getItem(key);
    // check is valid json value stored in storage, if true return parsed value else return as it is.
    if (isJson(value)) {
      value = JSON.parse(value);
    }
    return value;
  }

  /**
   * @param {string} key - To remove saved value from web storage
   */
  removeItem(key) {
    this.#sessionStorage.removeItem(key);
    this.#localStorage.removeItem(key);
  }

  // To remove all saved data from web (session/local) storage.
  clear() {
    this.#sessionStorage.clear();
    this.#localStorage.clear();
  }

  /**
   *
   * @param {*} key - To validate storage key.
   * @returns boolean - true, if key is valid, else false.
   */
  isValidKey(key) {
    // Check for blank string.
    if (!key.trim()) {
      console.warn('Storage key can not be empty string');
      return false;
    }

    /**
     * Valid key - only string
     * For example -'null', 'undefined', '2', true and others all are invalid key.
     */
    if (
      ['null', 'undefined'].includes(key) ||
      typeof key !== 'string' ||
      Number.isInteger(+key)
    ) {
      console.warn(
        'Storage key can be only type of string, please correct the storage key.'
      );
      return false;
    }
    return true;
  }

  /**
   *
   * @param {string | number | Object | Array | boolean} value - to be stored into web storage
   * @returns string
   */
  formatValue(value) {
    if (typeof value === 'string') {
      return value.trim();
    } else if (
      (value !== null && typeof value === 'object') ||
      typeof value === 'boolean'
    ) {
      return JSON.stringify(value);
    }
    return value;
  }
}

const storage = new Storage();
export default Object.freeze(storage);

import { SIGNUP_API_ENDPOINT } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class SignupService {
  // function to fetch shipment tracking details
  async postSignupDetails(payload) {
    const response = await HttpClient.post(SIGNUP_API_ENDPOINT.SIGNUP, payload);
    return response;
  }
}

export default new SignupService();

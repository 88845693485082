import { REGEX_PATTERNS } from '../config/constants';

// Checks whether if an email is valid
export const isValidEmail = (email) => {
  const emailPattern = REGEX_PATTERNS.EMAIL;
  return emailPattern.test(email);
};

// Checks whether if a phone number is valid
export const isValidPhoneNumber = (phoneNumber) => {
  const phonePattern = REGEX_PATTERNS.PHONE;
  return phonePattern.test(phoneNumber);
};

import { createSlice } from '@reduxjs/toolkit';

// Initial state for blogs slice
export const initialState = {
  latestBlogs: [],
  categoryBlogs: [],
  categoryWiseBlogs: [],
  categories: [],
  singleBlog: {},
};

const blogSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    updateLatestBlogs: (state, action) => {
      state.latestBlogs = action.payload;
    },
    updateCategoryBlogs: (state, action) => {
      state.categoryBlogs = action.payload;
    },
    updateCategories: (state, action) => {
      state.categories = action.payload;
    },
    updateSingleBlog: (state, action) => {
      state.singleBlog = action.payload;
    },
    updateCategoryWiseBlogs: (state, action) => {
      state.categoryWiseBlogs = action.payload;
    },
  },
});

export const blogActions = blogSlice.actions;
export const blogReducer = blogSlice.reducer;

export const BlogActionTypes = {
  FETCH_LATEST_BLOGS: 'blogs/FETCH_LATEST_BLOGS',
  FETCH_CATEGORY_BLOGS: 'blogs/FETCH_CATEGORY_BLOGS',
  FETCH_CATEGORIES: 'blogs/FETCH_CATEGORIES',
  FETCH_SINGLE_BLOG: 'blogs/FETCH_SINGLE_BLOG',
  FETCH_CATEGORY_WISE_BLOGS: 'blogs/FETCH_CATEGORY_WISE_BLOGS',
};

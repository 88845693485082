import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  status: null,
  isLoading: null,
  signupModal: false,
};

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    signupDataRequest: (state, action) => {
      state.isLoading = true;
    },
    signupDataSuccess: (state) => {
      state.isLoading = false;
      state.status = true;
    },
    signupDataFailure: (state) => {
      state.isLoading = false;
      state.status = false;
    },
    signupDataReset: (state) => {
      state.status = null;
      state.isLoading = null;
    },
    showSignupModal: (state) => {
      state.signupModal = true;
    },
    hideSignupModal: (state) => {
      state.signupModal = false;
    },
  },
});

export const signupActions = signupSlice.actions;
export const signupReducer = signupSlice.reducer;

import styled from 'styled-components';
import { DEVICES } from '../../config/appConfig';
export const FooterContainer = styled.div`
  background: #404040;
`;
export const FooterImg = styled.div`
  background: #ffffff;
  padding-top: 40px;
  .FooterDesk {
    display: block;
  }
  .FooterMobile {
    display: none;
  }
  @media ${DEVICES.sMobile} {
    .FooterDesk {
      display: none;
    }
    .FooterMobile {
      display: block;
    }
  }
`;
export const FooterLogo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  img {
    width: 20%;
  }
  @media ${DEVICES.tablet} {
    img {
      width: 20%;
    }
  }
  @media ${DEVICES.mobile} {
    img {
      width: 30%;
    }
  }
  @media ${DEVICES.sMobile} {
    img {
      width: 50%;
    }
  }
`;

export const FooterRow = styled.div`
  display: flex;
  max-width: 1170px;
  width: 100%;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  justify-content: center !important;
`;
export const FooterCol = styled.div`
  width: 22%;
  &:first-child {
    width: 29%;
  }
  ul {
    margin: 0px;
    padding: 0px;
    li {
      list-style: none;
      margin-bottom: 10px;
      a {
        color: #fff;
        text-decoration: none;
      }
      &:last-child img {
        margin-right: 10px;
      }
    }
  }

  @media ${DEVICES.tablet} {
    width: 21%;
    text-align: left;
    &:first-child {
      width: 32%;
    }
    li {
      font-size: 12px;
    }
  }
  @media ${DEVICES.mobile} {
    width: 22%;
    text-align: left;
    &:first-child {
      width: 31%;
    }
    li {
      font-size: 12px;
    }
  }
  @media ${DEVICES.sMobile} {
    width: 50%;
    text-align: center;
    &:first-child {
      width: 100%;
    }
    li {
      font-size: 12px;
    }
  }
`;
export const FooterTitle = styled.div`
  font-weight: 700;
  padding-bottom: 10px;
  color: #ffffff;
`;
export const FooterCopyRight = styled.div`
  width: 100%;
  text-align: center;
  background: #000;
  color: #8c8c8c;
  padding-top: 10px;
  padding-bottom: 10px;
`;
